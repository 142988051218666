'use client';

import ImageAsset from '@/components/ImageAsset';
import Box from '@/components/ui/Box';
import Btn from '@/components/ui/Btn';
import Flex from '@/components/ui/Flex';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import Video from '@/components/Video';
import { createBlock } from '@/lib/parsers/blocks';
import { parseLinkField } from '@/lib/parsers/linkField';
import { useBreakpointMin } from '@/theme';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';
import { gql } from '__generated__';
import { BlockContainer } from '../../BlockContainer';

const IMAGE_AND_TEXT_BLOCK = gql(`
  fragment imageAndTextBlock on blocks_imageAndText_BlockType {
      heading
      content: htmlContentDefault
      linkField {
        ...linkField
      }
      imageSingle {
        ...ImageAsset
      }
      videoUrl
      flipAlignment
  }    
`);

const ImageAndTextBlock = createBlock(IMAGE_AND_TEXT_BLOCK, ({ data, meta }) => {
  const { imageSingle, videoUrl, heading, content, linkField, flipAlignment } = data;
  const links = parseLinkField(linkField);
  const lg = useBreakpointMin('lg');

  const MediaModule = () => {
    return (
      <Grid.Col lg={6}>
        {!!videoUrl ? (
          <Video videoUrl={videoUrl} controls={false} playing loop muted />
        ) : (
          <ImageAsset data={imageSingle} ratio="LANDSCAPE" />
        )}
      </Grid.Col>
    );
  };

  const TextModule = () => {
    return (
      <Grid.Col lg={6}>
        <Flex justifyContent="center" rowGap="sm" cx={{ mX: { lg: 'gutter' }, height: 'full' }}>
          <Txt as="h3" variant="h3" noMargin>
            {heading}
          </Txt>
          <Txt variant="body" noMargin html>
            {content}
          </Txt>
          <Box>
            {links?.map((link, i) => (
              <Btn key={`${link?.title}-${i}`} variant="nav" underline="always" {...link} />
            ))}
          </Box>
        </Flex>
      </Grid.Col>
    );
  };

  return (
    <BlockContainer {...{ meta, marginY: true }}>
      <Grid rowGutter>
        {toBoolean(flipAlignment) && lg ? (
          <>
            <TextModule />
            <MediaModule />
          </>
        ) : (
          <>
            <MediaModule />
            <TextModule />
          </>
        )}
      </Grid>
    </BlockContainer>
  );
});

export default ImageAndTextBlock;
