'use client';

import { vars } from '@/theme/vars.css';
import { calc } from '@vanilla-extract/css-utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { ProfileEntryFragment } from '__generated__/graphql';
import Box from '../ui/Box';
import Flex from '../ui/Flex';
import Grid from '../ui/Grid';
import * as styles from './PageHeader.css';
import Profile from './Profile';
import { usePageHeader } from './usePageHeader';

type PageHeaderProfileProps = ProfileEntryFragment & React.PropsWithChildren;

export default function PageHeaderProfile({ children, ...props }: PageHeaderProfileProps) {
  const { height } = usePageHeader();

  const pad = calc.multiply(vars.spacing.md, 2);
  const boxHeight = height ?? 0;

  return (
    <Box
      className={styles.profileWrapper}
      style={assignInlineVars({
        [styles.profileHeight]: `${calc.add(pad, `${boxHeight}px`)}`,
      })}>
      <Box colorSet="gumLight" paper className={styles.profile}>
        <Flex columnGap="xs" cx={{ p: 'md', direction: 'column' }}>
          {children}
          <Grid>
            <Grid.Col xs={8}>
              <Profile {...props} />
            </Grid.Col>
          </Grid>
        </Flex>
      </Box>
    </Box>
  );
}
