import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fpalette.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63VW2vCMBgG4Ht%2FRaA3ClVyaJI2u5rO%2FI3Rg3WbzmyZuhP%2B95FuOj%2BVDwISyMXblyftRyHGO7cm3z1ChsN7vdh4Lqghfl6VfS5ESnimwyZSQkdaDm6Oi2xfzFToyJRwyS4V%2Bb4oeSiyv%2B28KA5FsS8KdamYHd6xCMVwvpSH4q5nzj9LGpJQSgGjDEnsbVgg1oYk0zwsEOeGJG3bgqwIqpZjqUBcBqGw2goQV%2BE8ZaUdg7gO8cQyy0DchPjOsukExLNgW6usBXFrSDLjtPvEXW%2F0ly7gEOaGbEvf%2Fx8KHOvDyeN8cGwtcUvjlgTWM26pGGuFW3mM5XCriJnXC26VMe%2F1iltVjOVxq46x3nCribHWuDWLsTa41cZY2yv%2BX%2B%2FQejwpU2g9odYHbrEY6xO3eIz1hVsixmIUx7IojF1h%2BpVrfodVu6Xzp5W0u2Q6pyrrxdy7zaoZXqrmaXebBPMHOJy6D4UHAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42U23KCMBCG732KvdSpZJINhJA%2BQ5%2BhgxiUloMFqtVO370DgkwgmfH2z%2B63h39B1VXVwu8KwPPeWYq8vARUwVtc1tVJb6GJy8ZrdJ2lr0YMeyIGn4jhCpI8Lk5rToJaF1tI4jxZI4m4iHjYSfACjLCQny%2BbLfi1LjYmwZ8IaCAkhkKOCEqkpD2CE9HHzTDBiEFyT3pwWMiYnFrxhRg4FoqYKMLsBilau1kywolhEHgQ4SMfo%2FtC0D6NVIC1LkwxUsBI34Kpx46mGaGhEGNNThCFy4SdrV7iqLfv9KWs7XLaybjUD6pbowV%2F7B4sembdK3OdmsWZj4lg7ikS8uFMIMPBGYsvnyOBmVfGiB9Y72OoNMPkE2ZGsTWyzC%2Bm%2FJnjvvSXRzZUmUHKzhhuWXTVPTCLZSfX%2Fhjyx%2BxIuD9%2BYbbZvxz3ULuqNgrYQmxd1%2FPtuJ6zAp9SU7soEHPtR0Ew164KPEoozpm3QQ%2B6h7%2FVrtpf%2B19xWpWt12Q3bY6UZ6X2jjo7HFvjy8p12%2Braa05xkpUHg%2FkP2ZDYO98FAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fgrid.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tZTVmcmxpMDogMTI7CiAgLS1lNWZybGkxOiAxNnB4OwogIC0tZTVmcmxpMjogMjRweDsKICAtLWU1ZnJsaTM6IDI0cHg7Cn0%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fspacing.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tcDNvNWdtMDogMDsKICAtLXAzbzVnbTE6IDFweDsKICAtLXAzbzVnbTI6IDJweDsKICAtLXAzbzVnbTM6IHZhcigtLWU1ZnJsaTMpOwogIC0tcDNvNWdtNDogdmFyKC0tZTVmcmxpMSk7CiAgLS1wM281Z201OiA0MHB4OwogIC0tcDNvNWdtNjogMC4xMjVyZW07CiAgLS1wM281Z203OiAwLjI1cmVtOwogIC0tcDNvNWdtODogMC4zNzVyZW07CiAgLS1wM281Z205OiAwLjVyZW07CiAgLS1wM281Z21hOiAwLjc1cmVtOwogIC0tcDNvNWdtYjogMXJlbTsKICAtLXAzbzVnbWM6IDEuMjVyZW07CiAgLS1wM281Z21kOiAxLjVyZW07CiAgLS1wM281Z21lOiAycmVtOwogIC0tcDNvNWdtZjogMi4zNzVyZW07CiAgLS1wM281Z21nOiBjbGFtcCgyLjM3NXJlbSwgY2FsYygxLjY3MTE4NzVyZW0gKyAxLjQ2NnZ3KSwgM3JlbSk7CiAgLS1wM281Z21oOiBjbGFtcCgzcmVtLCBjYWxjKDIuNDM2OTM3NXJlbSArIDEuMTczdncpLCAzLjVyZW0pOwogIC0tcDNvNWdtaTogY2xhbXAoMy41cmVtLCBjYWxjKDIuMzczODc1cmVtICsgMi4zNDZ2dyksIDQuNXJlbSk7CiAgLS1wM281Z21qOiBjbGFtcCgzLjVyZW0sIGNhbGMoMS44MTA4NzVyZW0gKyAzLjUxOXZ3KSwgNXJlbSk7CiAgLS1wM281Z21rOiBjbGFtcCg1cmVtLCBjYWxjKDIuMTg0NzVyZW0gKyA1Ljg2NXZ3KSwgNy41cmVtKTsKfQ%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fvars.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42UUXKCMBCG33uKfdQZcbKBgG4v0Bv0sRMElQoEAyq207t3wqglSaudgWH58u%2B%2FS0hCWqkOPp8AgqBXQsgzIwiQsecRQgKHcALkYkxCAhSWJiKw3gWBSOKmH7OYIIkXNktMNR7ZcEGAkWA2XBLgkjtQEnCbpAStKotszFZOcxkBmwudV2OYG7hIXLwmwDlyF28IuGexJThKPQmCN1zzsD6JdjoeLgjQyXg3RT3znakZeq2Ufn7lVtxbFWs%2FQ5mKnnXj%2BpSWz958rT8J2vTpT1lLwJp%2BuOOmB71J5YTN4HLNMbS8ux81ssfyw0j%2BD%2Fcj3frg1zw3hYvp7GYa%2FSFCYfmehh%2FXjlFPg8xiZ1%2F2YVBoIWSGRTbDYZHajBOkcrXbaHWos2ClSqVncHmkSme5vsKhE8hlmwdFHahDZ%2FmEv%2FncT4kIVCNXRXd%2BIBQEnZZ1u1a6eiCNCbZ5sdl2M6hk%2F3INi%2Foanoqs2w6Dr5eoqC%2FRfefE2fG4IHAOGVwSOIcRShoWlcVSgnA4db6%2BAaJ0Sig%2FBQAA%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fui%2FTxt%2FTxt.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA82Y0Y6iMBSG732Kc7mbgBEqjoMPsylQaMfSMqWos5t99404jrZj9mgIhjtiv%2Fb%2FU8s5P53%2FimpjoqZbwp8ZQK6lNinsqPkRhuehRQBCcWaE%2FbmZAZRa2XDPRMWtT8bX5N%2FZ%2FPx70i9%2BteLXxJdtZ2KSuPgKwVcu%2FoLgLy6%2BRvC1i78i%2BKuLUwSnLp4heObiOYLnLl4geOHiDMGZi5cIXrp41eM1NZVQYaat1XUKi%2FmK1Q7Gb2IOIjzh%2BHIOy5iofbJ3ld8w%2FuDy256%2Fec5P%2FO7yIpS0FvLDBxYXoBW%2FmT8s%2BmEpFAv5TQHiGpJjG3rDDC1dQ%2FXYhraYIa9kqLENScyQV5T02IZqzJBX9pqxDSnMkFdY3y%2BGbutF%2F9frMD2vXJmBevZBvXagXovpedXbDtQzmJ7XXLqBeu%2BYntf7dmMf4AYz5PXu%2FWBDyA5pzJCXPQjQ6xT3GcWOa1h2sGHBcm2oFVql0KmCmePK3gLc1jKATBcf9%2Fy7ztTWGq0qbE%2F2%2FrTs4RmdDECfWmJDi0KoKpSstClE83XOPViK%2B2IHAR5Nre0T4PHUOj8BTqbW%2FAnw5dT6PwGeTC0CEOCrqaUAAvFhaKd8NAkQGCz5aBgg0NZPzgME6uLJkYCArJ6cCggcRv86ejQYEK%2BBTiUd8CgAHgfASQB8GQBPgnNFuKM%2FWppJFoDlAdjTwcq0KZhJIWoO0GopCsgkzbebr6Ew11LSpmUpnJ8238Wi71L8vs3rYw2VolIpHCNAr0zzbWV0p4rQvc26XNf491w3730I2CI4O%2FnMGecZDdFJVfcn8R%2BlcyLjQRMAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var root = {fn:_7a468({defaultClassName:'_1mrr1pu4',variantClassNames:{color:{black:'_1mrr1pu5',greyLight:'_1mrr1pu6',greyMid:'_1mrr1pu7',white:'_1mrr1pu8',gum:'_1mrr1pu9',gumLight:'_1mrr1pua',pinkLight:'_1mrr1pub',redLight:'_1mrr1puc',orangeLight:'_1mrr1pud',blueLight:'_1mrr1pue',error:'_1mrr1puf'},autoMargin:{true:'_1mrr1pug'},noMargin:{true:'_1mrr1puh'},bold:{true:'_1mrr1pui'},heading:{true:'_1mrr1puj'},variant:{h1:'_1mrr1puk',h2:'_1mrr1pul',h3:'_1mrr1pum',h4:'_1mrr1pun',h5:'_1mrr1puo',h6:'_1mrr1pup','2xs':'_1mrr1puq',xs:'_1mrr1pur',sm:'_1mrr1pus',md:'_1mrr1put',lg:'_1mrr1puu',xl:'_1mrr1puv',body:'_1mrr1puw',inherit:'_1mrr1pux'}},defaultVariants:{},compoundVariants:[]}),variantKeys:['color','autoMargin','noMargin','bold','heading','variant']};
export var txtVars = {color:'var(--_1mrr1pu0)',display:'var(--_1mrr1pu1)',fontWeight:'var(--_1mrr1pu2)'};
export var typographyClasses = {h1:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5i)',lineHeight:'var(--_1f23nw53)'},h2:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5j)',lineHeight:'var(--_1f23nw54)'},h3:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5k)',lineHeight:'var(--_1f23nw55)'},h4:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5l)',lineHeight:'var(--_1f23nw56)'},h5:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5m)',lineHeight:'var(--_1f23nw57)'},h6:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5n)',lineHeight:'var(--_1f23nw58)'},'2xs':{fontFamily:'var(--_1f23nw51)',fontSize:'var(--_1f23nw5u)',lineHeight:'var(--_1f23nw5e)'},xs:{fontFamily:'var(--_1f23nw51)',fontSize:'var(--_1f23nw5t)',lineHeight:'var(--_1f23nw5e)'},sm:{fontFamily:'var(--_1f23nw51)',fontSize:'var(--_1f23nw5s)',lineHeight:'var(--_1f23nw5d)'},md:{fontFamily:'var(--_1f23nw51)',fontSize:'var(--_1f23nw5r)',lineHeight:'var(--_1f23nw5c)'},lg:{fontFamily:'var(--_1f23nw51)',fontSize:'var(--_1f23nw5q)',lineHeight:'var(--_1f23nw5b)'},xl:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5p)',lineHeight:'var(--_1f23nw5a)'},body:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw51)',fontSize:'var(--_1f23nw5o)',lineHeight:'var(--_1f23nw59)'},inherit:{}};
export var withHtml = '_1mrr1pu3';