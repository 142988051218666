import { useAppContext } from '@/lib/context/hooks';
import { parseUri } from '@liquorice/utils';
import { HomeEntryFragment } from '__generated__/graphql';
import { useTranslations } from 'next-intl';
import Box, { BoxProps } from '../ui/Box';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './PageHeader.css';

type PageHeroCardProps<D extends React.ElementType = 'div'> = BoxProps<
  D,
  { PageProps?: Partial<HomeEntryFragment> }
>;

export default function PageHeroCard({ PageProps, ...props }: PageHeroCardProps) {
  const t = useTranslations('entryIndex');
  const { commonLinks } = useAppContext() ?? {};
  const { entryTitle, entrySummary } = PageProps ?? {};

  const profileIndexUri = parseUri(commonLinks?.profileIndex?.uri);
  const serviceIndexUri = parseUri(commonLinks?.serviceIndex?.uri);

  return (
    <Box tint className={styles.pageHeroCard} {...props}>
      {entryTitle && (
        <Txt as="h2" variant="h2">
          {entrySummary}
        </Txt>
      )}
      <Flex direction="row" alignItems="center" columnGap="2xs">
        <Txt variant="sm">{t('imLookingFor')}:</Txt>
        <Flex direction="row" columnGap="3xs">
          {profileIndexUri && (
            <Btn variant="outlined" size="small" color="gum" href={profileIndexUri}>
              {t('person')}
            </Btn>
          )}
          {serviceIndexUri && (
            <Btn variant="outlined" size="small" color="gum" href={serviceIndexUri}>
              {t('service')}
            </Btn>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
