'use client';

import { SERVICE_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { simplifyEntry } from '@/lib/utils/format';
import { withFragment } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements, makeNonNullableArray, parseUri } from '@liquorice/utils';
import Card from '../Card';
import { OverrideCardProps } from './EntryCard';
import { INSIGHT_CARD_FRAGMENT } from './InsightCard';

const HighlightInsightCard = withFragment(
  INSIGHT_CARD_FRAGMENT,
  (data, props: OverrideCardProps) => {
    if (!data) return null;

    const { entryImage, entrySummary, entryTitle, uri, serviceMultiple } = data;
    const { heading } = props;

    const href = parseUri(uri);
    const maybeImage = makeNonNullableArray(props.image);
    const image = !!maybeImage.length ? maybeImage : entryImage;
    const services = excludeEmptyTypedElements(serviceMultiple);
    const categories = simplifyEntry(SERVICE_CARD_FRAGMENT, services);

    return (
      <Card
        disableLinkWrap
        elevation={false}
        item={{
          title: heading ?? entryTitle,
          titleComponent: 'a',
          categories,
          description: entrySummary,
          image,
          href,
        }}>
        <Card.Body cx={{ rowGap: 'sm', mB: 'sm' }}>
          <Card.Title variant="h3" />
          <Card.Categories variant="outlined" color="gum" />
          <Card.Description />
        </Card.Body>
        <Card.Foot>
          <Card.Cta as="a" href={href} />
        </Card.Foot>
      </Card>
    );
  }
);

export default HighlightInsightCard;
