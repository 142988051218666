import Card, { CardProps } from '../Card';
import Box from '../ui/Box';

export default function BasicCard(props: CardProps) {
  const { href } = props.item;

  return (
    <Card elevation={false} padding="sm" {...props}>
      <Box>
        <Card.Image ratio="LANDSCAPE_WIDE" />
      </Box>
      <Card.Body>
        <Card.Title variant="h5" />
        <Card.Description />
      </Card.Body>
      {href && (
        <Card.Foot>
          <Card.Cta />
        </Card.Foot>
      )}
    </Card>
  );
}
