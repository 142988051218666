import { getFragmentData } from '__generated__';
import { EntryCardsFragment } from '__generated__/graphql';
import { SERVICE_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import {
  ARTICLE_CARD_FRAGMENT,
  INSIGHT_CARD_FRAGMENT,
  OVERVIEW_CARD_FRAGMENT,
  PROFILE_CARD_FRAGMENT,
  STANDARD_CARD_FRAGMENT,
} from '../EntryCards';

type EntryCards = EntryCardsFragment | null;

export const useEntryCards = (data: EntryCards) => {
  let fragment;
  let typeLabel = '';

  if (!data) return null;

  switch (data?.__typename) {
    case 'article_Entry':
      fragment = getFragmentData(ARTICLE_CARD_FRAGMENT, data);
      typeLabel = 'Article';
      break;
    case 'insight_Entry':
      fragment = getFragmentData(INSIGHT_CARD_FRAGMENT, data);
      typeLabel = 'Insight';
      break;
    case 'service_Entry':
      fragment = getFragmentData(SERVICE_CARD_FRAGMENT, data);
      typeLabel = 'Sector';
      break;
    case 'profile_Entry':
      fragment = getFragmentData(PROFILE_CARD_FRAGMENT, data);
      typeLabel = 'Profile';
      break;
    case 'overview_Entry':
      fragment = getFragmentData(OVERVIEW_CARD_FRAGMENT, data);
      typeLabel = 'Insight';
      break;
    case 'standard_Entry':
      fragment = getFragmentData(STANDARD_CARD_FRAGMENT, data);
      typeLabel = 'Insight';
      break;
    default:
      typeLabel = 'Page';
      break;
  }

  const typename = fragment?.__typename;

  return { fragment, typeLabel, typename };
};
