import NextAnchor, { NextAnchorProps } from '@/components/ui/NextAnchor';
import Txt, { TxtProps } from '@/components/ui/Txt';
import { ENTRY_CARD_FRAGMENTS } from '@/gql/fragments/entryCards.gql';
import { createFragmentParser } from '@liquorice/gql-utils';
import { parseUri } from '@liquorice/utils';
import { useEntryCards } from '../Entry/useEntryCards';
import { EntryResultItem } from '../EntryIndex/entryIndexTypes';
import Btn, { BtnProps } from '../ui/Btn';
import * as styles from './SearchBar.css';

export type SearchBarResultProps = Omit<NextAnchorProps, 'href'> & {
  item: EntryResultItem;
  TitleProps?: TxtProps<'p'>;
  LabelProps?: BtnProps;
};

const SearchBarResult = ({ TitleProps, LabelProps, item, ...props }: SearchBarResultProps) => {
  const parseEntryCards = createFragmentParser(ENTRY_CARD_FRAGMENTS, (data) => data);
  const entryCard = parseEntryCards(item);
  const card = useEntryCards(entryCard);

  const title = card?.fragment?.entryTitle;
  const uri = parseUri(card?.fragment?.uri);

  return (
    uri && (
      <NextAnchor href={uri} className={styles.resultItem} {...props}>
        <Txt as="p" variant="body" noMargin {...TitleProps}>
          {title}
        </Txt>
        <Btn size="small" variant="outlined" color="gum" {...LabelProps}>
          {card?.typeLabel}
        </Btn>
      </NextAnchor>
    )
  );
};

export default SearchBarResult;
