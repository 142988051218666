import { gql } from '__generated__';

export const ENTRY_ID_FRAGMENT = gql(`
  fragment entryId on EntryInterface {
    __typename
    id
    title
    parent {
      id
      title
    }
  }
`);

export const ENTRY_BASE_FRAGMENT = gql(`
  fragment entryBase on EntryInterface {
    __typename
    typeHandle
    searchScore
    id
    uri
    url
    slug
    title
    sectionHandle
    postDate @formatDateTime(format: "j F Y")
    parent {
        id
        uri
        title
    }
  }
`);

export const THEME_ENTRY_FRAGMENT = gql(`
  fragment themeEntry on theme_Entry {
    ...entryBase
    title
    imageSingle {
      ...ImageAsset
    }
    colorScheme {
      label
    }
  }  
`);

export const ENTRIES_FRAGMENT = gql(`
  fragment entries on EntryInterface {
    ...articleEntry
    ...articleIndexEntry
    ...homeEntry
    ...searchEntry
    ...pageStandardEntry
    ...pageOverviewEntry
    ...profileEntry
    ...profileIndexEntry
    ...serviceIndexEntry
    ...serviceEntry
    ...insightIndexEntry
    ...insightEntry
  }    
`);
