'use client';

import { CommonLink } from '@/lib/getters/getCommonLinks';
import { useBreakpointMax, useBreakpointMin } from '@/theme';
import { ProfileCardFragment } from '__generated__/graphql';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import Box from '../ui/Box';
import Divider from '../ui/Divider';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import { KeyContactsProps } from './KeyContacts';
import KeyContactsCta from './KeyContacts.Cta';
import KeyContactItems from './KeyContacts.Items';

const KeyContactsWrap = ({
  type,
  isMobile = false,
  isDesktop = false,
  id,
  data,
  profileIndex,
  optionalMax,
}: KeyContactsProps & { data: ProfileCardFragment[]; profileIndex?: CommonLink | null }) => {
  const defaultMax = type === 'article' || type === 'insight' ? 20 : 6;

  const maxItems = optionalMax ?? defaultMax;

  const t = useTranslations();
  const maxLg = useBreakpointMax('lg');
  const minLg = useBreakpointMin('lg');

  const [value, setValue] = useState<boolean>(maxLg);

  useEffect(() => {
    if (isDesktop) {
      setValue(minLg);
    } else if (isMobile) {
      setValue(maxLg);
    }
  }, [maxLg, minLg, isMobile, isDesktop]);

  return (
    value && (
      <Box cx={{ mT: '5xl', mB: '3xl' }}>
        <Txt as="p" variant="h6">
          {t('common.keyContacts')}
        </Txt>
        <Divider cx={{ mB: 'xs' }} />
        <Flex rowGap="2xs" cx={{ mB: '2xs' }}>
          <KeyContactItems items={data} maxItems={maxItems} />
        </Flex>
        <KeyContactsCta {...{ type, id, items: data, indexUri: profileIndex?.uri }}>
          {t('actions.viewAll')}
        </KeyContactsCta>
      </Box>
    )
  );
};

export default KeyContactsWrap;
