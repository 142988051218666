import { PROFILE_ROLE_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { simplifyCategory } from '@/lib/utils/format';
import { parseUri } from '@liquorice/utils';
import { ProfileCardFragment } from '__generated__/graphql';
import ImageAsset from '../ImageAsset';
import Divider from '../ui/Divider';
import Flex from '../ui/Flex';
import Grid from '../ui/Grid';
import Txt from '../ui/Txt';

type KeyContactItemsProps = {
  items: ProfileCardFragment[];
  minItems?: number;
  maxItems?: number;
};

const KeyContactItems = ({ items, minItems = 0, maxItems = 6 }: KeyContactItemsProps) => {
  return items.slice(minItems, maxItems).map((profile) => {
    const roles = simplifyCategory(PROFILE_ROLE_CATEGORY_FRAGMENT, profile?.profileRoleCategory);

    return (
      <Grid
        key={profile.id}
        as="a"
        href={parseUri(profile.uri)}
        cx={{ columnGap: '2xs', decoration: 'none' }}>
        <Grid.Col xs={2}>
          <ImageAsset round data={profile.entryImage} />
        </Grid.Col>
        <Flex direction="row" alignItems="center" columnGap="4xs">
          <Txt variant="xs" color="black">
            {profile?.entryTitle}
          </Txt>
          <Divider direction="vertical" style={{ height: '0.7em' }} />
          {roles?.map((role) => {
            return (
              <Txt key={role.id} variant="xs" color="black">
                {role.title}
              </Txt>
            );
          })}
        </Flex>
      </Grid>
    );
  });
};
export default KeyContactItems;
